<template>
  <div :style="`width : ${width}`">
    <h5 v-if="label && !noLabel" style="color : var(--wsMIDDLE); font-size: 13px; font-weight: 500 " class="mb-2">  {{  label  }} </h5>

    <v-switch class="mt-4 ml-2" v-model="text"  inset background-color="transparent" color="green lighten-1" >
      <template #label>
        <slot>
          <h5 v-if="placeholder">{{ placeholder }} </h5>
          <wsTooltip v-if="tooltip" :text="tooltip">
            <v-icon class="ml-2" color="wsACCENT">mdi-information-outline</v-icon>
          </wsTooltip>
        </slot>
      </template>
    </v-switch>





  </div>
</template>

<script>
export default {
  name: "wsTextField",
  props : {
    value : {
      type : [String,Boolean]
    },
    label : {
      type : String,
    },
    placeholder : {
      type : String,
    },
    tooltip : {
      type : String,
    },
    error : {
      type : Boolean,
      default : false
    },
    autocomplete : {
      type : Boolean,
      default : false
    },
    counter : {
      type : String,
    },
    height : {
      type : String,
    },
    clearable : {
      type : Boolean,
      default : false
    },
    width : {
      type :String,
      deafult : '100%'
    },
    prependInnerIcon : {
      type :String,
    },
    disabled : {
      type : Boolean,
      default : false
    },
    noLabel : {
      type : Boolean,
      default : false
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.value !== this.text ) {
        this.$emit('input',this.text)
      }
    }
  },
  data() {
    return {
      text : false,
      focused : false
    }
  },
  computed : {
    type() {
      if ( this.number ) {
        return 'number'
      }
      return 'text'
    }
  },
  methods : {
    handleKeyPress($event , element ) {
      if ( this.number ) {
        this.NUMBER_INPUT($event,element, parseInt(this.numberLength) )
      }
    }
  },
  mounted() {
    this.text = this.value
  }
}
</script>

<style lang="css" scoped>
/*.v-text-field--outlined >>> fieldset {*/
/*  border-color: rgba(192, 0, 250, 0.986);*/
/*}*/
.notActive.v-text-field--outlined >>> fieldset {
  border-color: #9AB2C4 !important;
}
.activeText.v-text-field--outlined >>> fieldset {
  border-color: #567186 !important;
  border-width: 2px;
}
.notActive.v-text-field--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.errorStyle.v-text-field--outlined >>> input::placeholder {
  border-color: darkred !important;
  border-width: 2px;
}
.textStyle {
  font-weight: 500;
  font-size: 14px;
}



</style>